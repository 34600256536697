<template src="./rating.html"></template>

<script>
import mixins from "../../../mixins/mixins.js";
export default {
  mixins: [mixins],
  data() {
    return {
      window: {
        width: 0,
        height: 0,
      },
      ratingAppointment: null,
      starSize: 25,
      name: "",
      date: "",
      professional: "",
      clinic: "",
      reason: null,
      disabledButtonSendRating: true,
      canEdit: null,
      idMedicalRecord: null,
      token: null,
      textCardFinishRating: "Agradecemos sua participação! Sua avaliação foi finalizada com sucesso.",
      showCardRating: true,
    };
  },
  watch: {
    ratingAppointment: function(){
      const vm = this;
      vm.reason = null;
      vm.disabledButtonSendRating = vm.testNullUndefinedEmpty(vm.reason);
    },
    reason: function(newVal){
      const vm = this;
      if(vm.ratingAppointment <= 5 && vm.testNullUndefinedEmpty(newVal) === false){
        vm.disabledButtonSendRating = false;
      } else if(vm.ratingAppointment <= 5 && vm.testNullUndefinedEmpty(newVal)){
        vm.disabledButtonSendRating = true;
      }
    },
  },
  created() {
    const vm = this;
    window.addEventListener("resize", vm.handleResize);
    vm.handleResize();
  },
  destroyed() {
    const vm = this;
    window.removeEventListener("resize", vm.handleResize);
  },
  mounted() {
    const vm = this;
    vm.getInfoMedicalRecord();
  },
  methods: {
    handleResize() {
      const vm = this;
      vm.window.width = window.innerWidth;
      vm.window.height = window.innerHeight;
      if (vm.window.width < 380) {
        vm.starSize = 20;
      }
    },
    setRatingAppointment() {
    },
    getInfoMedicalRecord() {
      const vm = this;
      vm.idMedicalRecord = vm.$route.params.idMedicalRecord;
      vm.token = vm.$route.params.token;

      vm.axios
        .get(
          vm.$store.state.filooServer +
            "medical-records/"+ vm.idMedicalRecord + "/quick-access?token=" + vm.token)
        .then(function (response) {
          if (response.status === 200) {
            console.log(response.data);
            vm.name = response.data.nomePaciente;
            vm.date = response.data.date;
            vm.professional = response.data.nomeMedico;
            vm.clinic = response.data.nomeConsultorio;
            vm.canEdit = response.data.canEdit;
            if(vm.canEdit === false){
              vm.textCardFinishRating = "Sua avaliação já consta na nossa base de dados. Agradecemos sua participação, sua opinão é muito importante para nós!";
              vm.showCardRating = false;
            } else {
              vm.showCardRating = true;
            }
          }
        })
        .catch(function (error) {
          vm.$swal("Atenção!", "Não foi possível realizar esta operação. " + error.response.data.message + " Por favor, contacte o administrador do sistema." , "error");
          console.log({ error_getInfoMedicalRecord: error });
        });
    },
    sendRating() {
      const vm = this;
      const rating = {
        estrelas: vm.ratingAppointment,
        comentario: vm.reason,
      }
      vm.axios
        .put(
          vm.$store.state.filooServer +
            "medical-records/"+ vm.idMedicalRecord +"/quick-access?token=" + vm.token , rating)
        .then(function (response) {
          if (response.status === 200) {
            vm.textCardFinishRating = "Agradecemos sua participação! Sua avaliação foi finalizada com sucesso.";
            vm.showCardRating = false;
          }
        })
        .catch(function (error) {
          vm.$swal("Atenção!", "Não foi possível realizar esta operação. " + error.response.data.message + " Por favor, contacte o administrador do sistema." , "error");
          console.log({error_sendRating: error});
        });
    },
  },
};
</script>

<style lang="scss" src="./rating.scss">
</style>
